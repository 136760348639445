@import '~@xpo-ltl/ngx-ltl-core/styles/variables';

$corrections-subtitle-font-size: 16px;

$corrections-content-margin: 0 15px;
$corrections-content-gap: 8px;
$corrections-section-gap: 16px;
$corrections-navbar-min-width: 70px;
$corrections-card-border: 1px solid $xpo-grey--300;
$corrections-disable-text-color: $xpo-grey--350;
$corrections-title-color: $xpo-black;
$corrections-title-hover-color: $xpo-grey--350;
$corrections-default-background: $xpo-white;

$corrections-column-gap--sm: 8px;
$corrections-column-gap--md: 16px;
$corrections-column-gap--lg: 32px;
$corrections-column-gap--xl: 64px;

$corrections-row-gap--sm: 8px;
$corrections-row-gap--md: 16px;
$corrections-row-gap--lg: 32px;
$corrections-row-gap--xl: 64px;

$corrections-breakpoin--xs: $xpo-breakpoint--small-xs;
$corrections-breakpoin--sm: $xpo-breakpoint--small;
$corrections-breakpoin--md: $xpo-breakpoint--medium;
$corrections-breakpoin--lg: $xpo-breakpoint--large;
$corrections-breakpoin--xl: $xpo-breakpoint--xLarge;

$corrections-header-margin: 16px 16px 0 16px;

$corrections-warning-background: $xpo-yellow--350;

$corrections-alert-color: $xpo-red--200;
